import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { adApis } from "../services/apis";
import getConstants from "../services/globalStates/constants";
import { userSlice } from "./Slices/User";
import { dashboardSlice } from "./Slices/Dashboard";

const reducers = combineReducers({
  userInformation: userSlice.reducer,
  dashboardInformation: dashboardSlice.reducer,
  [adApis.reducerPath]: adApis.reducer,
  constants: getConstants,
});

const rootReducer = (state, action) => {
  if (action.type === "logout/logout") {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-keyForAD@360@$",
      onError: function (error) {
        console.warn(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adApis.middleware),
});

export default store;
