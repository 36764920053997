import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboardInformation",
  initialState: {
    dashboardInformation: {}, 
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardInformation = { ...state.dashboardInformation, ...action.payload };
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;