import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "../src/App.css";
import Loader from "./common/Loader";
// import SetAdminPass from "./Pages/Authentication/SetAdminPass";

const SignUp = lazy(() => import("../src/Pages/Authentication/SignUp"));
const Approval = lazy(() => import("../src/Pages/Authentication/Approval"));
const ApprovalStatus = lazy(() =>
  import("../src/Pages/Authentication/ApprovalStatus")
);
const ForgotPassword = lazy(() =>
  import("../src/Pages/Authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../src/Pages/Authentication/ResetPassword")
);
const PasswordChanged = lazy(() =>
  import("../src/Pages/Authentication/PasswordChanged")
);
const SetAdminPass = lazy(() =>
  import("../src/Pages/Authentication/SetAdminPass")
);
const ThankYou = lazy(() => import("../src/Pages/Authentication/ThankYou"));
const PrivacyPolicies = lazy(() => import("../src/Pages/PrivacyPolicies"));
const Login = lazy(() => import("../src/Pages/Authentication/Login"));
const Dashboard = lazy(() =>
  import("../src/Components/Dashbord/DashboardInitial")
);
const UploadEmployees = lazy(() =>
  import("./Components/Employees/UploadEmployees")
);
const Settings = lazy(() => import("./Pages/Settings/Settings"));
const Employees = lazy(() => import("./Pages/Employees/Employees"));
const Challenge = lazy(() => import("./Pages/Challenge/Challenge"));
const VerifyOtp = lazy(() => import("./Pages/Authentication/VerifyOtp"));
const DashboardConcept = lazy(() =>
  import("./Components/Dashbord/DashboardConcept")
);
const EmployeeList = lazy(() => import("./Components/Employees/EmployeeList"));
const ChallengeList = lazy(() =>
  import("./Components/Challenge/ChallengeList")
);
const Faq = lazy(() => import("./Components/UserCMS/Faq"));
const TermsAndCondition = lazy(() =>
  import("./Components/UserCMS/TermsAndCondition")
);
const PrivacyStatement = lazy(() =>
  import("./Components/UserCMS/PrivacyStatement")
);
const ChallengeDetail = lazy(() =>
  import("./Components/Challenge/ChallengeDetails")
);
const CurrentPage = () => {
  const location = useLocation();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-[#222266] to-[#343495e5]">
      <h1 className="text-5xl font-bold text-white mb-4">Page Not Found</h1>
      {/* <p className="text-lg text-gray-100">
        No match for <code className="bg-gray-200 p-1 rounded">{location.pathname}</code>
      </p> */}
      {/* <p className="text-lg text-gray-100">
      Please enter the correct URL.
      </p> */}
    </div>
  );
};

const RootRedirect = () => {
  const sessionToken = localStorage.getItem("sessionToken");
  const loginStatus = localStorage.getItem("loginStatus");

  return sessionToken && loginStatus == "true" ? <Navigate to="/onboarding" /> : <Navigate to="/login" />;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const PrivateOutlet = () => {
    const sessionToken = localStorage.getItem("sessionToken");
    

    return sessionToken ? <Outlet /> : <Navigate to="/login" />;
  };

  function isSessionExpired() {
    const lastActivityTime = localStorage.getItem("lastActivityTime");
    if (lastActivityTime) {
      return Date.now() - parseInt(lastActivityTime, 10) > 3600000; // 1 hour in milliseconds
    }
    return true;
  }
  useEffect(() => {
    if (isSessionExpired()) {
      localStorage.clear();
      navigate("/login");
      // window.location.reload(); 
    }
  }, []);

  function handleActivity() {
    localStorage.setItem("lastActivityTime", Date.now().toString());
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, []);

  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    const loginStatus = localStorage.getItem("loginStatus");
    if (sessionToken && loginStatus == "true") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Routes>
        {/* <Route path="" element={<Login />} /> */}
        <Route path="/" element={<RootRedirect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up/" element={<SignUp />} />
        <Route path="/verify-otp/:id" element={<VerifyOtp />} />
        <Route path="/basic-details" element={<Approval />} />
        <Route path="/privacy-policies/:id" element={<PrivacyPolicies />} />
        <Route path="/approval-status/:id" element={<ApprovalStatus />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:id" element={<ResetPassword />} />
        <Route path="/password-changed" element={<PasswordChanged />} />
        <Route path="/set-password/:id" element={<SetAdminPass/>} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route element={<PrivateOutlet />}>
          <Route path="/onboarding" element={<Dashboard />} />
          <Route path="/upload-employees" element={<UploadEmployees />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/employees" element={<Employees />} /> */}
          <Route path="/createchallenge" element={<Challenge />} />
          <Route path="/dashboard" element={<DashboardConcept />} />
          <Route path="/employees" element={<EmployeeList />} />
          <Route path="/challenges" element={<ChallengeList />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/privacy-statement" element={<PrivacyStatement />} />
          <Route path="/challenge-detail" element={<ChallengeDetail />} />
        </Route>
        <Route path="*" element={<CurrentPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
