import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adApis = createApi({
  reducerPath: "ad360Apis",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
  }),
  refetchOnMountOrArgChange: 0.001,
  keepUnusedDataFor: 0.000001,
  skip: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (data) => ({
        url: "/onboarding/signup",
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({otp, userId, language}) => ({
        url: `/onboarding/verifyOtp?language=${language}`,
        method: "POST",
        body: { otp, userId },
      }),
    }),
    submitForApproval: builder.mutation({
      query: (data) => ({
        url: "/onboarding/submitForApproval",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    login: builder.mutation({
      query: ({ company_email, password, lang }) => ({
        url: `/onboarding/login?language=${lang}`,
        method: "POST",
        body: { company_email, password },
      }),
    }),
    resendOTP: builder.mutation({
      query: ({userId, language}) => ({
        url: `/onboarding/resendOtp?language=${language}`,
        method: "POST",
        body: {userId},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    sendResetLink: builder.mutation({
      query: ({company_email,lang}) => ({
        url: `/onboarding/sendResetLink?language=${lang}`,
        method: "POST",
        body: {company_email},
      }),
    }),
    userCsvUpload: builder.mutation({
      query: (data) => ({
        url: `/onboarding/csv-upload`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    submitCsvUpload: builder.mutation({
      query: ({lang}) => ({
        url: `/onboarding/submitCSVUpload?language=${lang}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    createChallenge: builder.mutation({
      query: (data) => ({
        url: `/onboarding/addOrganizationCommunity`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    listChallenge: builder.mutation({
      query: (data) => ({
        url: `/onboarding/getOrganizationCommunityList`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    challengeDetails: builder.mutation({
      query: (data) => ({
        url: `/onboarding/getOrganizationCommunityDetail`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    challengeDelete: builder.mutation({
      query: (data) => ({
        url: `/onboarding/deleteOrgCommunitiesChallenge`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    listEmployees: builder.mutation({
      query: ({page, limit, sortConfig}) => ({
        url: `/onboarding/getOrganizationEmployeeList?page=${page}&limit=${limit}&sortBy=${sortConfig?.sortBy}&sortOrder=${sortConfig?.sortOrder}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    deleteEmployee: builder.mutation({
      query: (data) => ({
        url: `/onboarding/deleteOrganizationEmployee`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    updateEmployee: builder.mutation({
      query: (data) => ({
        url: `/onboarding/updateOrganizationEmployee`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    dashboardDetails: builder.mutation({
      query: (data) => ({
        url: `/onboarding/dashboard`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    termsAndCondition: builder.mutation({
      query: (page) => ({
        url: `/onboarding/getTermsAndCondition`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    privacyPolicy: builder.mutation({
      query: (page) => ({
        url: `/onboarding/getPrivacyPolicy`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    termsOfService: builder.mutation({
      query: (page) => ({
        url: `/onboarding/getTermsAndServices`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    shareChallenge: builder.mutation({
      query: (data) => ({
        url: `/onboarding/shareOrganization`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    challengeLeader: builder.mutation({
      query: (data) => ({
        url: `/onboarding/challenge/${data?.id}?page=${data?.page}&size=${data?.limit}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/onboarding/changePassword`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    updatePage: builder.mutation({
      query: (data) => ({
        url: `/onboarding/upadtePageStatus`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    updateLogo: builder.mutation({
      query: (data) => ({
        url: `/onboarding/orgImage`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    webIndex: builder.mutation({
      query: () => ({
        url: `/onboarding/webIndex`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    inviteLink: builder.mutation({
      query: (id) => ({
        url: `/onboarding/inviteLink/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    employeeDetails: builder.mutation({
      query: (data) => ({
        url: `/onboarding/getEmployeeById`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/onboarding/resetPassword`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    subadminPassword: builder.mutation({
      query: (data) => ({
        url: `/onboarding/setPassword`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    exportEmployees: builder.mutation({
      query: (id) => ({
        url: `/onboarding/exportEmployees/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    challengeLeaderboard: builder.mutation({
      query: (id) => ({
        url: `/onboarding/challengeLeaderboard?page=${id.page}&limit=${id.limit}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    getFaq: builder.mutation({
      query: (data) => ({
        url: `/onboarding/faqs?lang=${data=='rtl'?"Arabic" : "English"}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    updateChallenge: builder.mutation({
      query: ({ data, id }) => ({
        url: `onboarding/organizationChallenge/${id}`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    getGraph: builder.mutation({
      query: () => ({
        url: `/onboarding/engagedEmployees`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    exportReports: builder.mutation({
      query: ({ data, reportName }) => ({
        url: `/onboarding/exportReports?report=${reportName}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
    updateLanguage: builder.mutation({
      query: (data) => ({
        url: `/onboarding/orgLang`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }),
    }),
  }),
});
export const {
  useSignUpMutation,
  useVerifyOtpMutation,
  useSubmitForApprovalMutation,
  useLoginMutation,
  useResendOTPMutation,
  useSendResetLinkMutation,
  useUserCsvUploadMutation,
  useSubmitCsvUploadMutation,
  useCreateChallengeMutation,
  useListChallengeMutation,
  useChallengeDetailsMutation,
  useChallengeLeaderMutation,
  useChallengeDeleteMutation,
  useListEmployeesMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useDashboardDetailsMutation,
  useTermsAndConditionMutation,
  usePrivacyPolicyMutation,
  useTermsOfServiceMutation,
  useShareChallengeMutation,
  useChangePasswordMutation,
  useUpdatePageMutation,
  useUpdateLogoMutation,
  useWebIndexMutation,
  useInviteLinkMutation,
  useEmployeeDetailsMutation,
  useForgotPasswordMutation,
 useSubadminPasswordMutation,
  useExportEmployeesMutation,
  useChallengeLeaderboardMutation,
  useGetFaqMutation,
  useUpdateChallengeMutation,
  useGetGraphMutation,
  useExportReportsMutation,
  useUpdateLanguageMutation,


} = adApis;
