import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { TourProvider, useTour, Popover } from "@reactour/tour";
import { useTranslation, Trans } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useSelector } from "react-redux";

let persistor = persistStore(store);

const CustomNextButton = () => {
  const { setCurrentStep, currentStep, stepsLength } = useTour();
  const { t, i18n } = useTranslation();

  const goToNextStep = () => {
    const nextStep = currentStep + 1; // Calculate next step index
    setCurrentStep(nextStep); // Update current step

    const nextSelector = steps[nextStep]; // Access next step's selector if defined

    if (nextSelector?.selector) {
      const element = document.querySelector(nextSelector?.selector);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to next selector
        document.body.classList.remove("tour-start");
      }
    } else {
      console.warn(`Next step selector not found for step index ${nextStep}`);
    }
  };

  return (
    <button
      className="text-black font-normal text-[12px] border-none rounded-3xl bg-[#00E7B7] px-3 py-2  outline-none"
      onClick={goToNextStep}
    >
      {t("next")}
    </button>
  );
};

const disableBody = (target) => {
  disableBodyScroll(target);
 
};
const enableBody = (target) => {
  enableBodyScroll(target);

};

const GetTranslatedText = ({ textKey }) => {
  const { t } = useTranslation();
  return <>{t(textKey)}</>;
};

const styles = {
  maskWrapper: (base) => ({
    ...base,
    color: "red",
  }),
  highlightedArea: (base, { x, y }) => ({
    ...base,
    x: x + 10,
    y: y + 10,
  }),
  badge: (base) => ({ ...base, color: "blue" }),
};

const steps = () => {
  return [
    {
      selector: ".first-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="addingEmployees" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="letsGetStartedLink" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">1/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
      highlightedSelectors: [".custom-highlighted-mask"],
    },
    {
      selector: ".second-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            {" "}
            <GetTranslatedText textKey="createAChallenge" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="onceYouhaveOnboard" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">2/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
    },
    {
      selector: ".third-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="needtoHaveAn" />
          </p>
          <p className="text-[12px] font-normal">
            <GetTranslatedText textKey="byVisitingTheDashboard" />
          </p>

          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">3/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
      position: 'right'
    },
    {
      selector: ".fourth-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="wantToStart" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="inThisSection" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">4/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
         position: 'right'
    },
    {
      selector: ".fifth-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="needToAdd" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="followTheSame" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">5/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
         position: 'right'
    },
    {
      selector: ".sixth-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            {" "}
            <GetTranslatedText textKey="needAnEmployeeOverView" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="hereYouWillAble" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">6/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),

         position: 'right'
    },
    {
      selector: ".seventh-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="wantToTrack" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="inThisSectionYouWillLive" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">7/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
         position: 'right'
    },
    {
      selector: ".eighth-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="needToChangeSomeAccount" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="youWillbeAbleToChange" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">8/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
         position: 'right'
    },
    {
      selector: ".ninth-step",
      content: (
        <div>
          <p className="text-[17px] font-normal">
            <GetTranslatedText textKey="haveAnyQuestion" />
          </p>
          <p className="text-[12px] font-normal mt-3">
            <GetTranslatedText textKey="thisSectionWillHelp" />
          </p>
          <div className="flex justify-between mt-4">
            <p className="text-[#00E7B7] text-[12px] text-center">9/9</p>
            <CustomNextButton />
          </div>
        </div>
      ),
         position: 'right'
    },
  ];
};

const opositeSide = {
  top: "bottom",
  bottom: "top",
  right: "left",
  left: "right",
};

function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === "custom") {
    return {};
  }

  const width = 16;
  const height = 12;
  const color = "#5050B4";
  const isVertical = position === "top" || position === "bottom";
  const spaceFromSide = 15;

  const obj = {
    [`--rtp-arrow-${
      isVertical ? opositeSide[horizontalAlign] : verticalAlign
    }`]: height + spaceFromSide + "px",
    [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
    [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };
  return obj;
}

function Close({ onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        right: localStorage?.getItem("dir") === "ltr" ? 15 : "auto",
        left: localStorage?.getItem("dir") === "rtl" ? 15 : "auto",
        top: 15,
      }}
    >
      <span

      // onClick={handleSkip2}
      >
        <svg
          class="fill-current w-[24px] h-[24px] text-[#fff] bg-[#9696D2] rounded text-[8px]"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </button>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TourProvider
            steps={steps}
            onClickHighlighted={(e, clickProps) => {
              console.log('No interaction at all')
              if (clickProps.currentStep == 8) {
                e.stopPropagation()
                e.preventDefault()
                clickProps.setCurrentStep(
                  Math.min(clickProps.currentStep + 1, clickProps.steps.length - 1)
                )
              }
            }}
            components={{ Close }}
            afterOpen={disableBody}
            beforeClose={enableBody}
          
            padding={{
              mask: 0,
              popover: [5, 10],
              wrapper: 0,
            }}
            styles={{
              popover: (base, state) => ({
                ...base,
                backgroundColor: "#5050B4",
                color: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                maxWidth: "343px",
                height: "fit",
                ...doArrow(
                  state.position,
                  state.verticalAlign,
                  state.horizontalAlign
                ),
              }),
              maskArea: (base) => ({ ...base, rx: "10" }),
              maskWrapper: (base) => ({
                ...base,
                color: "rgba(34, 34, 76, 0.6)",
              }),

              dot: (base, state) => ({
                ...base,
                display: "none",
                backgroundColor: state.current ? "#333" : "#bbb",
              }),
              arrow: (base) => ({
                ...base,
                display: "none", // Hide the arrow
              }),
              maskArea: (base) => ({
                ...base,
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Background color of the mask area
                borderRadius: "8px", // Rounded corners
                zIndex: "9999", // Ensure mask area is above other content
                padding: "10px", // Adjust the padding to trim the mask
                boxSizing: "border-box", // Ensure padding is included in the width and height
              }),             

              badge: (base) => ({
                ...base,
                display: "none",
                backgroundColor: "#333",
              }),
              controls: false,
            }}
            disableInteraction
          >
            <App />
          </TourProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
